var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "book-summary-skelton"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-3"
  }, [_c('div', {
    staticClass: "image"
  }, [_c('div')]), _c('div', {
    staticClass: "info"
  }, [_c('div')])]), _c('div', {
    staticClass: "col-lg-9"
  }, [_c('div', {
    staticClass: "details"
  }, [_c('div'), _c('div'), _c('div'), _c('div'), _c('div'), _c('div'), _c('div'), _c('div')]), _c('div', {
    staticClass: "actions"
  }, [_c('div')])])])]);
}]

export { render, staticRenderFns }