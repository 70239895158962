var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "book-video-skelton"
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('div')])]);
}]

export { render, staticRenderFns }