var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "magazineSwiper",
    staticClass: "magazine-swiper"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, [!_vm.isLoadingMagazines ? _vm._l(_vm.recentMagazines, function (magazine) {
    return _c('div', {
      key: magazine.id,
      staticClass: "swiper-slide"
    }, [_c('magazine-card', {
      attrs: {
        "magazine": magazine
      },
      on: {
        "share-magazine": _vm.onShareMagazine
      }
    })], 1);
  }) : [_c('cover-loader', {
    attrs: {
      "size": "75px"
    }
  })]], 2), _c('share-popup', {
    attrs: {
      "entity": _vm.magazine
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }