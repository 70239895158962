var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "recent-magazines-section"
  }, [_c('home-section-header', {
    attrs: {
      "title": "صدر حديثاً",
      "route-name": "ZadiMagazines"
    }
  }), _c('magazine-card-wrapper', {
    attrs: {
      "recent-magazines": _vm.recentMagazines,
      "is-loading-magazines": _vm.isLoadingRecentMagazines
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }